import {
  FlowEditorSDK,
  GetAppManifestFn,
  TFunction,
} from '@wix/yoshi-flow-editor';

import componentJson from './.component.json';
import {
  getMembersAreaContext,
  MembersAreaContext,
} from '../../editor/services/members-area-api';

type DePromisify<T> = T extends Promise<infer U> ? U : T;

type AppManifest = DePromisify<ReturnType<GetAppManifestFn>>;

export const MANAGE_FIELDS_EV_ID = 'manage-fields-clicked';

export const createAppManifest = async (
  t: TFunction,
  editorSDK: FlowEditorSDK,
): Promise<AppManifest> => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  const isMembersAreaV2Context = membersAreaContext === MembersAreaContext.V2;
  const isMembersAreaV3Context = membersAreaContext === MembersAreaContext.V3;
  const helpIdV2 = 'f168bedd-2549-4a04-920b-b0b49bb4c7c1';
  const helpIdV3 = 'f168bedd-2549-4a04-920b-b0b49bb4c7c1';

  return {
    controllersStageData: {
      [componentJson.id]: {
        default: {
          behavior: {
            removable: false,
          },
          gfpp: {
            desktop: {
              helpId: isMembersAreaV3Context
                ? helpIdV3
                : isMembersAreaV2Context
                ? helpIdV2
                : undefined,
              mainAction2: {
                actionId: MANAGE_FIELDS_EV_ID,
                label: t('app.settings.main.secondary-cta'),
              },
              iconButtons: { widgetPlugins: 'HIDE' },
            },
            mobile: {
              iconButtons: {},
            },
          },
        },
      },
    },
  };
};
