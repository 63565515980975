import { FlowEditorSDK, Experiments } from '@wix/yoshi-flow-editor';
import { Experiment } from '../../types';

export const refreshApp = (
  editorSDK: FlowEditorSDK,
  experiments: Experiments,
  source: string,
) => {
  return experiments.enabled(Experiment.EnableOOIInEditor)
    ? editorSDK.document.application.livePreview.refresh('', {
        source,
        shouldFetchData: true,
      })
    : editorSDK.tpa.app.refreshApp('');
};

export const setSettingsParamForWidgets = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  key: string,
  value: boolean,
) => {
  const applicationId = (
    await editorSDK.tpa.app.getDataByAppDefId('', appDefId)
  )?.applicationId;

  if (!applicationId) {
    return;
  }

  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    applicationId,
  );

  const setSettingsParamForComp = async ({
    id,
  }: Parameters<FlowEditorSDK['components']['getById']>['1']) => {
    const compRef = await editorSDK.components.getById('', { id });
    await editorSDK.document.tpa.data.set('', {
      compRef,
      key,
      value,
      scope: 'COMPONENT',
    });
  };

  return Promise.all(appComponents?.map(setSettingsParamForComp));
};
